div#page-wrapper {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

body {
  color: #696969;
  flex-direction: column;
  justify-content: flex-start;
  font-family: sans-serif;
  font-size: 12pt;
  font-weight: 500;
  display: flex;
}

.frow {
  flex-flow: wrap;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.fcol {
  flex-direction: column;
  display: flex;
}

.revwrap {
  flex-wrap: wrap-reverse;
}

.fullheight {
  height: 100%;
}

.fullwidth {
  width: 100%;
}

.container {
  flex-direction: column;
  display: flex;
}

#content {
  flex-shrink: 1;
  width: 800pt;
}

a.content_index_item {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

div.content_index_item a.button {
  margin-bottom: .5em;
}

div.content_index_item div > p {
  text-align: left;
}

div.content_index_item {
  border-bottom: 1px solid #ddd;
  flex-flow: row;
  place-content: center start;
  align-items: stretch;
  min-height: 96pt;
  padding-top: 1em;
  padding-bottom: 1em;
  line-height: normal;
  display: flex;
}

article#MainContent .content_index_item img {
  max-width: 200pt;
  padding: 1em;
}

a.frontpage_article {
  color: #000;
  border-radius: .25em;
  margin-top: .5em;
  margin-bottom: .5em;
  padding: .5em;
  text-decoration: none;
}

a.frontpage_article:hover {
  background-color: #eee;
}

div.frontpage_article {
  flex-flow: row;
  place-content: center;
  align-items: center;
  min-height: 96pt;
  line-height: normal;
  display: flex;
}

div.frontpage_article span {
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  display: flex;
}

div.frontpage_article img {
  border-radius: .25em;
  height: 96pt;
  margin: 0;
  padding: 0;
}

div.frontpage_article div {
  flex-direction: column;
  flex-grow: 8;
  flex-shrink: 1;
  justify-content: center;
  align-items: flex-start;
  max-width: 350pt;
  padding-left: .5em;
  display: flex;
}

article#MainContent p:has(img) {
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 0;
}

article#MainContent p:has(span.photo-caption) {
  text-align: center;
  opacity: .65;
  margin-top: 0;
  padding-top: 0;
  font-size: .8em;
  font-style: italic;
}

a.sidebar-item {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

a.sidebar-item img {
  border-radius: 5px;
  width: 64px;
  height: 64px;
  margin-right: 8px;
}

a.sidebar-item p {
  margin: 0;
}

@media (orientation: portrait) {
  a.content_index_item {
    align-items: center;
  }

  div.content_index_item {
    text-align: center;
    flex-direction: column;
  }

  div.frontpage_article {
    flex-direction: column;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 2em;
    padding-right: 2em;
  }

  div.frontpage_article img {
    width: 100%;
    height: auto;
  }

  div.frontpage_article div {
    padding-top: 1em;
  }
}

div.frontpage_article h3 {
  flex-grow: 1;
  margin: 0;
  padding: 0 0 .5em;
}

div.frontpage_article p {
  flex-grow: 3;
  margin: 0;
}

.featurebox {
  border-radius: 2em;
  flex-flow: row;
  justify-content: center;
  margin: 1em;
  display: flex;
}

#frontpage_p2 h1 {
  text-align: center;
  color: #fff;
  align-self: center;
  padding: 1em;
  font-size: 3em;
}

@media (orientation: portrait) {
  .featurebox {
    flex-wrap: wrap;
  }

  #frontpage_p2 h1 {
    padding: 1em;
    font-size: 2em;
  }
}

.boxbg {
  background-color: #fff;
}

.featurebox section {
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
}

.frontpage_feature {
  width: 300pt;
  min-height: 500pt;
  padding: 1em;
  display: flex;
}

h1#qualifications {
  text-align: center;
  margin: .5em .5em 0;
}

.spfbox ul {
  max-width: 400pt;
  margin-bottom: 0;
  padding: 0 0 0 2em;
}

.spfbox li {
  margin: .5em;
  font-size: 1.5em;
  list-style: disc;
}

.spfbox p {
  margin: 0;
  padding: 0;
}

.spfbox {
  margin: 1em;
}

.spfbox img {
  border-radius: 2em;
  max-width: 100%;
  max-height: 225pt;
}

div.boxbg > .spfbox {
  justify-content: center;
  min-width: 80%;
}

.spfbox {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.spf_feature {
  width: 500pt;
  padding: 1em;
  display: flex;
}

.spf_feature ul {
  margin: 0;
  padding: 0 1em;
}

.spf_feature li {
  margin-left: 1em;
  list-style: disc;
}

.spf_feature div.fbox {
  justify-content: flex-start;
  align-items: stretch;
}

#frontpage_p2 {
  margin-bottom: 2em;
}

#frontpage_p3 {
  padding-top: 2em;
  padding-bottom: 2em;
}

#banner {
  width: 1000pt;
  margin-top: 5vh;
  margin-bottom: 10vh;
  padding: 4.5em;
  line-height: 1.3em;
  position: relative;
}

#banner h2 {
  margin: .1em 0 .35em;
  font-size: 3em;
  line-height: normal;
}

#banner p {
  margin: 0;
  font-size: 2em;
  line-height: 1.35em;
}

@media (orientation: portrait) {
  #banner {
    padding: 2.5em;
  }

  #banner h2 {
    font-size: 2em;
  }

  #banner p {
    font-size: 1.3em;
    line-height: 1.35em;
  }
}

#banner .button {
  width: 100%;
  margin-bottom: .5em;
}

#banner .button.icon:before {
  margin-top: -.5em;
  position: absolute;
  top: 50%;
  right: .15em;
}

#banner .button.alt {
  margin-bottom: 0;
}

#banner ul {
  margin: 0 0 0 2em;
}

#sidebar {
  border-right: 1px solid #eee;
  flex-direction: column;
  flex-shrink: 0;
  margin-right: 1.5em;
  padding-right: 1.5em;
  display: flex;
}

section.sidebar h3 {
  border-bottom: 1px solid #ccc;
  margin-top: 0;
  padding-top: 0;
}

section.sidebar {
  border-radius: 1em;
  padding: .5em;
}

section.sidebar ul {
  padding-left: 0;
}

section.sidebar li {
  border-radius: 1em;
  max-width: 220pt;
  margin-top: .5em;
  margin-left: 0;
  padding: .4em;
  line-height: 1.5em;
}

#maincont {
  background-color: #fff;
  border-radius: 2em;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: stretch;
  margin: 1em;
  padding: 3em 2em 5em;
  display: flex;
}

div.newsletter-signup {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  display: flex;
}

form input.newsletter-signup-email {
  border: 0;
  border-radius: 1em 0 0 1em;
  flex-grow: 2;
  margin: 0;
  padding: .2em .75em;
  font-size: 1.3em;
  line-height: 2em;
}

form button.newsletter-signup-submit {
  border-radius: 0 1em 1em 0;
  flex-grow: 1;
  margin: 0;
  padding: .55em;
  font-size: 1em;
  line-height: 2em;
}

@media (orientation: portrait) {
  #maincont {
    border-radius: 0;
    flex-wrap: wrap-reverse;
    margin: 0;
  }

  #sidebar {
    border: 0;
    flex-flow: wrap;
    justify-content: center;
    width: 100%;
    margin: 3em 0 0;
    padding: 0;
  }

  #Related, #Blog {
    flex-grow: 1;
    flex-shrink: 1;
  }

  section.sidebar {
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
  }

  section.sidebar li {
    max-width: none;
  }
}

div#cookie_banner {
  color: #fff;
  background: #000;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1em;
  display: flex;
  position: sticky;
  bottom: 0;
}

div#cookie_banner p {
  margin: 0;
  padding: 0;
}

div#cookie_banner span {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

div#cookie_banner .cb1 {
  color: #fff;
  background: #0090c5;
  border-radius: .3em;
  margin: .5em;
  padding: .5em;
  font-size: 1.5em;
  text-decoration: none;
}

div#cookie_banner .cb2 {
  color: #fff;
  background: #000;
  border: 1px solid #fff;
}

div#cookie_banner .button {
  margin-left: 2em;
}

a.social_share {
  font-size: 32pt;
  text-decoration: none;
}

a.social_share svg {
  width: 3em;
  height: 3em;
  margin-right: 1em;
}

/*# sourceMappingURL=style.css.map */
