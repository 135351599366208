div#page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

body {
    font-family: sans-serif;
    font-size: 12pt;
    color: #696969;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.frow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.fcol {
    display: flex;
    flex-direction: column;
}

.revwrap {
    flex-wrap: wrap-reverse;
}

.fullheight {
    height: 100%;
}

.fullwidth {
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
}

#content {
    width: 800pt;
    flex-shrink: 1;
}

a.content_index_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

div.content_index_item a.button {
    margin-bottom: 0.5em;
}

div.content_index_item div>p {
    text-align: left;
}

div.content_index_item {
    display: flex;
    flex-direction: row;
    flex-wrap:nowrap;
    align-items: stretch;
    align-content: center;
    justify-content: start;

    min-height: 96pt;
    line-height: normal;
    padding-top: 1em;
    border-bottom:1px solid #ddd;
    padding-bottom: 1em;
}
article#MainContent .content_index_item img {
    max-width: 200pt;
    padding: 1em;
}

a.frontpage_article {
    text-decoration: none;
    color: #000;
    padding: 0.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 0.25em;
}
a.frontpage_article:hover{
    background-color: #eee;
}

div.frontpage_article {
    display: flex;
    flex-direction: row;
    flex-wrap:nowrap;
    align-items: center;
    align-content: center;
    justify-content: center;

    min-height: 96pt;
    line-height: normal;
}
div.frontpage_article span {
    display: flex;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
}
div.frontpage_article img {
    height: 96pt;
    padding: 0;
    margin: 0;
    border-radius: 0.25em;
}
div.frontpage_article div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 1;
    flex-grow: 8;
    max-width: 350pt;

    padding-left: 0.5em;
}

article#MainContent p:has(img) {
    text-align: center;
    padding-bottom: 0;
    margin-bottom: 0;
}

article#MainContent p:has(span.photo-caption) {
    text-align: center;
    font-size: 0.8em;
    margin-top: 0;
    padding-top: 0;
    opacity: 0.65;
    font-style: italic;
}

a.sidebar-item {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
a.sidebar-item img {
    border-radius: 5px;
    width: 64px;
    height: 64px;
    margin-right: 8px;
}
a.sidebar-item p {
    margin: 0;
}

@media(orientation: portrait) {
    a.content_index_item {
        align-items: center;
    }
    div.content_index_item {
        flex-direction: column;
        text-align: center;
    }
    div.frontpage_article {
        flex-direction: column;
        margin-top: 1em;
        margin-bottom: 1em;
        padding-left: 2em;
        padding-right: 2em;
    }
    div.frontpage_article img {
        width: 100%;
        height: auto;
    }
    div.frontpage_article div {
        padding-top: 1em;
    }
}

div.frontpage_article h3 {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    padding-bottom: 0.5em;
}

div.frontpage_article p {
    flex-grow: 3;
    margin: 0;
}

.featurebox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    border-radius: 2em;
    margin: 1em;
}

#frontpage_p2 h1 {
    text-align: center;
    align-self: center;
    color: white;
    font-size: 3em;
    padding: 1em;
}

@media(orientation: portrait) {
    .featurebox {
        flex-wrap: wrap;
    }

    #frontpage_p2 h1 {
        font-size: 2em;
        padding: 1em;
    }
}

.boxbg {
    background-color: #fff;
}

.featurebox section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
}

.frontpage_feature {
    display: flex;
    width: 300pt;
    min-height: 500pt;
    padding: 1em;
}

/* .spf_feature section {
    box-shadow: 0em 1em rgba(0, 0, 0, 0.95);
} */
h1#qualifications {
    text-align: center;
    margin: 0.5em;
    margin-bottom: 0;
}
.spfbox ul {
    padding: 0em;
    max-width: 400pt;
    margin-bottom: 0;
    padding-left: 2em;
}
.spfbox li {
    list-style: disc;
    margin: 0.5em;
    font-size:1.5em;
}
.spfbox p {
    margin: 0;
    padding: 0;
}
.spfbox {
    margin: 1em;
}
.spfbox img {
    border-radius: 2em;
    max-height: 225pt;
    max-width: 100%;
}
div.boxbg > .spfbox {
    min-width: 80%;
    justify-content: center;
}
.spfbox {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.spf_feature {
    display: flex;
    width: 500pt;
    padding: 1em;
}
.spf_feature ul {
    margin: 0;
    padding: 0;
    padding-left: 1em;
    padding-right: 1em;
}
.spf_feature li{
    list-style: disc;
    margin-left: 1em;
}
.spf_feature div.fbox {
    justify-content: flex-start;
    align-items: stretch;
}

#frontpage_p2 {
    margin-bottom: 2em;
}

#frontpage_p3 {
    padding-top: 2em;   
    padding-bottom: 2em;   
}

/* Banner */

#banner {
    position: relative;
    padding: 4.5em;
    line-height: 1.3em;;
    width: 1000pt;
    margin-bottom: 10vh;
    margin-top: 5vh;
}
#banner h2 {
    font-size: 3.0em;
    margin: 0.1em 0 0.35em 0;
    line-height: normal;
}

#banner p {
    font-size: 2.0em;
    line-height: 1.35em;
    margin: 0;
}

@media (orientation: portrait){
    #banner {
        padding: 2.5em;
    }
    #banner h2 {
        font-size: 2.0em;
    }
    
    #banner p {
        font-size: 1.3em;
        line-height: 1.35em;
    }
}

#banner .button {
    width: 100%;
    margin-bottom: 0.5em;
}

#banner .button.icon:before {
    position: absolute;
    right: 0.15em;
    top: 50%;
    margin-top: -0.5em;
}

#banner .button.alt {
    margin-bottom: 0;
}

#banner ul {
    margin: 0 0 0 2em;
}
#sidebar {
    display: flex;
    flex-direction: column;
    padding-right: 1.5em;
    margin-right: 1.5em;
    border-right: 1px solid rgb(238, 238, 238);

    flex-shrink: 0;
}

section.sidebar h3 {
    margin-top: 0;
    padding-top: 0;
    border-bottom: 1px solid #ccc;
}
section.sidebar {
    border-radius: 1em;
    padding: 0.5em;
}
section.sidebar ul {
    padding-left: 0;
}
section.sidebar li {
    margin-left: 0;
    max-width: 220pt;
    line-height: 1.5em;
    margin-top: 0.5em;
    border-radius: 1em;
    padding: 0.4em;
}

#maincont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    flex-wrap: nowrap;
    margin: 1em;
    padding: 2em;
    padding-top: 3em;
    padding-bottom: 5em;
    background-color: white;
    border-radius: 2em;
}

div.newsletter-signup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
}
form input.newsletter-signup-email {
    margin: 0;
    padding: 0.2em 0.75em;
    line-height: 2em;
    border-radius: 1em 0em 0em 1em;
    border: 0;
    font-size: 1.3em;
    flex-grow: 2;
}
form button.newsletter-signup-submit {
    margin: 0;
    padding: 0.55em;
    border-radius: 0 1em 1em 0em;
    font-size: 1.0em;
    flex-grow: 1;
    line-height: 2em;
}

@media (orientation: portrait){
    #maincont {
        flex-wrap: wrap-reverse;
        margin: 0;  
        border-radius: 0;;
    }
    #sidebar {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        border: 0;
        margin: 0;
        margin-top: 3em;
        padding: 0;
        width: 100%;
    }

    #Related,#Blog {
        flex-grow: 1;
        flex-shrink: 1;
    }

    section.sidebar {
        flex-grow: 1;
        flex-shrink: 0;
        width: 100%;
    }
    section.sidebar li {
        max-width: none;
    }
}

div#cookie_banner {
    position: sticky;
    display: none;

    bottom: 0pt;
    padding: 1em;

    background: black;
    color: white;
    display: flex;

    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
div#cookie_banner p{
    padding: 0;
    margin: 0;
}
div#cookie_banner span{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
div#cookie_banner .cb1 {
    border-radius: 0.3em;
    background: #0090c5;
    padding: 0.5em;
    margin: 0.5em;
    font-size: 1.5em;
    color: white;
    text-decoration: none;
}
div#cookie_banner .cb2 {
    background: black;
    color: white;
    border: 1px solid white;
}

div#cookie_banner .button {
    margin-left: 2em;
}

a.social_share {
    text-decoration: none;
    font-size: 32pt;
}
a.social_share svg {
    height: 3em;
    width: 3em;
    margin-right: 1em;
}